.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0,0, 0, 0.15) !important;
    padding: 2em 3em !important;
}

.vertical-timeline.vertical-timeline-custom-line::before {
    background: #000;
}

.date {
    color: rgb(0, 0, 0);
    font-size: 40px;
}

#description {
    margin: 1em 0 1em 0;
    font-weight: 550;
}

.List {
    padding: 20px;
}

@media only screen and (max-width: 1100px) {
    .vertical-timeline-element-content {
        display: block !important;
        float: none !important;
        color: rgb(44, 44, 44);
        margin-top: 1.5em;
    }
}

