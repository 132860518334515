* {    
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    font-family: 'Dosis', sans-serif;
}

html, body {
   margin: 0;
   padding: 0;
   overflow-x: hidden;
}

.background__image {
    text-align: center;
    background-image: '/background1.jpg';
    min-width: 100%;
    min-height: 100%;
    background-size: cover;
    background-position: center;
}

.social {
    position: relative;
    margin-top: 60px;
}

:root {
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;

    --smooth-transition: all 400ms ease;

    --color-primary: #e83b36;
    --gradient-text: linear-gradient(#E39626, #FF480A);
    --gradient-text-2: linear-gradient(#fffe01, #ff8606);
}

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}
h1 {
    margin-bottom: 0px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    text-align: center;
    color: #fff;
    padding-bottom: 30px;
    padding-top: 80px;
}

.h5_subtitle {
    font-size: 20px;
    line-height: 1;
    font-weight: 400;
    text-align: center;
    color: #ddd;
    padding-bottom: 2rem;
    margin-right: 4rem;
    margin-left: 4rem;
}


section {
    padding-top: 0rem;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient__bg {

    /* ff 3.6+ */
    background:-moz-linear-gradient(150deg, rgba(232, 59, 54, 1) 0%, rgba(232, 59, 54, 1) 50%, rgba(255, 134, 6, 1) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(150deg, rgba(232, 59, 54, 1) 0%, rgba(232, 59, 54, 1) 50%, rgba(255, 134, 6, 1) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(150deg, rgba(232, 59, 54, 1) 0%, rgba(232, 59, 54, 1) 50%, rgba(255, 134, 6, 1) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF8606', endColorstr='#E83B36', GradientType=1 );

    /* ie 10+ */
    background:-ms-linear-gradient(150deg, rgba(232, 59, 54, 1) 0%, rgba(232, 59, 54, 1) 50%, rgba(255, 134, 6, 1) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(150deg, rgba(232, 59, 54, 1) 0%, rgba(232, 59, 54, 1) 50%, rgba(255, 134, 6, 1) 100%);

}