.contact__container {
    color: var(--color-primary);
    line-height: 1.6;
    margin-top: 30px;
    padding: 1em;
}

.contact__container > * {
    padding: 1em;
}

.contact__container form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.contact__container form label {
    display: block;
    color: indigo;
    font-weight: 600;
}

.contact__container form p {
    margin: 0;
}

.contact__container form .full {
    grid-column: 1/3;
}

.contact__container form input, .contact__container form textarea {
    width: 100%;
    padding: 1em;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {
    .contact__container form p {
        grid-column: 1/3;
    }
}