.certification__tab {
    display: flex;
    flex-direction: column;

    padding: 2rem;

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 65% 64%, rgba(187, 73, 107, 1) 0%, rgba(187, 73, 107, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 65% 64%, rgba(187, 73, 107, 1) 0%, rgba(187, 73, 107, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 65% 64%, rgba(187, 73, 107, 1) 0%, rgba(187, 73, 107, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 65% 64%, rgba(187, 73, 107, 1) 0%, rgba(187, 73, 107, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 65% 64%, rgba(187, 73, 107, 1) 0%, rgba(187, 73, 107, 1) 100%);
    
    filter: drop-shadow(4px 8px 8px rgba(0, 0, 0, 0.25));
}
